@import '/src/variables.less';

.standard-footer {
  background-color: #162d45;
  color: white;

  .footer-row {
    height: 100%;

    // Temporary while I get other things working
    padding: 0px 32px;
  }

  // These settings are to handle the floating footer
  height: @standardFooterHeight;
  padding-top: 15px;
  padding-left: 15px;

  // Divider
  .ant-divider {
    color: white;
    border-left-color: white;
  }

  // Icons
  .anticon {
    border: solid 2px white;
    border-radius: 50%;
    padding: 6px;
    font-size: 18px;
  }

  // Center and handle the items
  .left-side {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;